import type { DistributionGroup } from '@forgd/contract/types'

export const chartColors = {
  demographics: {
    default: '#C7C7BE',
    colors: [
      '#BB8C23',
      '#254699',
      '#A1C490',
      '#3F6E3B',
      '#C1A9C6',
      '#4E2E77',
      '#D6A97F',
    ],
  },
  demand: [
    '#B9C9A1',
    '#8AAE6F',
    '#628C42',
    '#375E29',
    '#27492C',
  ],
  supply: [
    '#510C0C',
    '#881B1B',
    '#B23A3A',
    '#CE7474',
    '#E8B0B0',
  ],
  supplyDemand: [
    '#B23A3A',
    '#628C42',
  ],
  distributionPreview: [
    '#BB8C23',
    '#254699',
    '#A1C490',
    '#3F6E3B',
  ],
  tokenDesigner: [
    '#BB8C23',
    '#254699',
    '#A1C490',
    '#3F6E3B',
    '#C1A9C6',
    '#4E2E77',
    '#D6A97F',
    '#C55E00',
    '#D99393',
    '#972323',
    '#DB9700',
    '#2C5CD7',
    '#89C563',
    '#0B8300',
    '#9C6FD6',
    '#510FA7',
    '#F8A75B',
    '#EE5600',
    '#FFAAAA',
    '#CC0000',
  ],
  tokenUnlocks: [
    '#BB8C23',
    '#254699',
    '#A1C490',
    '#3F6E3B',
    '#C1A9C6',
    '#4E2E77',
    '#D6A97F',
    '#C55E00',
    '#D99393',
    '#972323',
    // DUPLIcates so it doesn't look super-brown
    '#3F6E3B',
    '#C1A9C6',
  ],
  unovis: [
    '#4D8CFD',
    '#FF6B7E',
    '#F4B83E',
    '#A6CC74',
    '#00C19A',
    '#6f5aec',
  ],
  trend: {
    '1': '#057a55',
    '0': '#374151',
    '-1': '#dc2626',
  },
}

export interface Emission {
  month: number
  [key: string]: number
}

export type EmissionPreview = Array<Emission>

export interface DemandEstimate {
  months_post_tge: number
  demand_drivers: {
    [key: string]: number
  }
}

// This function takes an array like this:
//
// [
//   {
//     "month": 1,
//     "Core Contributors": 0,
//     "Advisors": 0,
//     "Seed Investors": 0,
//   },
//   {
//     "month": 1,
//     "Core Contributors": 2,
//     "Advisors": 2,
//     "Seed Investors": 2,
//   }
// ]
//
// And...
// 1. Gets all the keys except for "month"
// 2. Sorts by the last month's distribution (and then alphabetical)
// 3. Returns an array of colors _in the order of keys in the original object_ to pass in to Unovis

export function generateEmissionChartColors(chartData: EmissionPreview | undefined | null) { // Accept undefined or null
  if (!chartData || !Array.isArray(chartData) || chartData.length === 0) {
    return {
      colorArray: [],
      colors: [],
    }
  }

  const finalMonth = chartData[chartData.length - 1]

  if (!finalMonth) { // Check if finalMonth exists
    return {
      colorArray: [],
      colors: [],
    }
  }

  const colors: Array<{ name: string, color?: string }> = []
  const groups: Array<{ name: string, distributionPercent: number }> = []

  Object.keys(finalMonth).forEach((key) => {
    if (key !== 'month') {
      groups.push({ name: key, distributionPercent: finalMonth[key] })
    }
  })

  const sorted: Array<{ name: string, distributionPercent: number }> = groups.toSorted((a, b) => {
    if (a.distributionPercent === b.distributionPercent) {
      return a.name.localeCompare(b.name)
    }
    return b.distributionPercent - a.distributionPercent
  })

  sorted.forEach((group, index) => {
    colors.push({ name: group.name, color: chartColors.tokenDesigner[index % chartColors.tokenDesigner.length] })
  })

  return {
    colorArray: groups.map(group => colors?.find(color => color.name === group.name)?.color || ''),
    colors,
  }
}

// 1. sort by allocationPercentage, then group name
// 2. assign color from chartColors.tokenDesigner[]
export function generateDistributionGroupColors(groups: Array<DistributionGroup>) {
  return groups
    .toSorted((a, b) => {
      if (a.allocationPercentage === b.allocationPercentage) {
        return a.name.localeCompare(b.name)
      }
      return b.allocationPercentage - a.allocationPercentage
    })
    .map((group, index) => {
      return {
        ...group,
        color: chartColors.tokenDesigner[index % chartColors.tokenDesigner.length],
      }
    })
}
